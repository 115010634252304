import React from "react"
import { graphql, PageProps } from "gatsby"

import { useAnalytics } from "../hooks"

import {
  Header,
  Layout,
  SEO,
  SignOffMailingList,
  ContactForms,
  SectionsHandler,
} from "../components"
import { pageType, GatsbyLocationObjectType } from "../types"

import FacebookLogo from "../images/icons/facebook--blue.svg"
import InstagramLogo from "../images/icons/instagram--blue.svg"
import LinkedInLogo from "../images/icons/linkedIn--blue.svg"
import TwitterLogo from "../images/icons/twitter--blue.svg"

type Props = PageProps & {
  location: GatsbyLocationObjectType
  data: pageType & {
    site: {
      siteMetadata: {
        social: {
          twitter: string
          linkedIn: string
          facebook: string
          instagram: string
        }
      }
    }
  }
}

export default ({ data }: Props) => {
  const analytics = useAnalytics()

  const url = process.env.GATSBY_SITE_URL + "/contact/"
  analytics.track(["User", "Page View"], { page: "/contact/" })

  return (
    <Layout>
      <SEO
        title={data.contentfulPages.seo.title}
        description={data.contentfulPages.seo.description.description}
        image={data.contentfulPages.seo.socialImage?.file?.url}
        url={url}
      />
      <Header heading={data.contentfulPages.heading} textAlignment="center">
        <div className="socialLinks">
          <a
            href={data.site.siteMetadata.social.facebook}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={FacebookLogo} alt="Facebook logo" title="Facebook logo" />
          </a>
          <a
            href={data.site.siteMetadata.social.linkedIn}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={LinkedInLogo} alt="LinkedIn logo" title="LinkedIn logo" />
          </a>
          <a
            href={data.site.siteMetadata.social.twitter}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={TwitterLogo} alt="Twitter logo" title="Twitter logo" />
          </a>
          <a
            href={data.site.siteMetadata.social.instagram}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={InstagramLogo}
              alt="Instagram logo"
              title="Instagram logo"
            />
          </a>
        </div>
        <ContactForms />
      </Header>
      <main>
        <SectionsHandler sections={data.contentfulPages.sections} />
      </main>
      <SignOffMailingList />
    </Layout>
  )
}

export const pageQuery = graphql`
  query contactDetails {
    site {
      siteMetadata {
        social {
          twitter
          linkedIn
          facebook
          instagram
        }
      }
    }
    contentfulPages(page: { eq: "Contact" }) {
      contentful_id
      heading
      headerIntroduction {
        raw
      }
      headerImage {
        gatsbyImageData
        title
      }
      seo {
        socialImage {
          file {
            url
          }
        }
        description {
          description
        }
        title
      }
      sections {
        ... on ContentfulPageSectionsCourseGroups {
          contentful_id
          name
          __typename
          courseGroups {
            ... on ContentfulCourseGroups {
              name
              homepageDescription {
                homepageDescription
              }
              image {
                title
                gatsbyImageData
              }
              contentful_id
              slug
            }
            ... on ContentfulCourseTopics {
              name
              homepageDescription {
                homepageDescription
              }
              image {
                gatsbyImageData
                title
              }
              contentful_id
              slug
            }
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsFaQs {
          __typename
          contentful_id
          heading
          faQs {
            heading
            content {
              raw
            }
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsHowItWorks {
          __typename
          contentful_id
          heading
          steps {
            contentful_id
            description {
              description
            }
            heading
            icon {
              file {
                url
              }
              gatsbyImageData
            }
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsStats {
          __typename
          contentful_id
          stats {
            name
            description {
              description
            }
            number
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsTextWithImage {
          __typename
          id
          contentful_id
          image {
            gatsbyImageData
            title
            url
          }
          video {
            url
            title
          }
          imageAlignment
          heading
          content {
            raw
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsStrap {
          __typename
          id
          contentful_id
          buttonSupportingText
          description
          name
          buttonText
          buttonUrl
          backgroundColour
        }
      }
    }
  }
`
